import { Alignment, Button, ButtonGroup, Menu, MenuItem, Navbar, Popover, Position } from "@blueprintjs/core";
import React from "react";

import { OUTSETA_KNOWLEDGE_BASE_URL } from "../auth/providers/outseta/outseta.constants";
import { useAuth } from "../auth/useAuth";
// @ts-expect-error
import logo from "../images/tradeblotter.png";
import { useDialog } from "../providers/DialogContext";

const NavbarComponent = () => {
  const { openDialog } = useDialog();
  const { user, logout, openProfile } = useAuth();
  const handleMenuItemClick = (dialogId: string) => {
    openDialog(dialogId);
  };

  const dropdownMenu = (
    <Menu>
      <MenuItem text="Create portfolio" onClick={() => handleMenuItemClick("create-portfolio-dialog")} />
      {/* <MenuItem
                text="Create alert"
                onClick={() => handleMenuItemClick("create-alert-dialog")}
            />*/}
      <MenuItem text="Create API key" onClick={() => handleMenuItemClick("create-api-key-dialog")} />
    </Menu>
  );

  const dropdownUser = (
    <Menu>
      <MenuItem text={`${user?.FirstName}'s Profile`} onClick={() => openProfile()} />
      <MenuItem
        text="Visit Knowledge Base"
        onClick={() => {
          window.open(OUTSETA_KNOWLEDGE_BASE_URL, "_blank");
        }}
      />

      <MenuItem text="Logout" onClick={() => logout()} />
    </Menu>
  );

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <img src={logo} alt="Trade Blotter" style={{ height: "30px", marginRight: "10px" }} />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <ButtonGroup minimal={true}>
          <Button
            outlined
            intent="primary"
            icon="import"
            onClick={() => handleMenuItemClick("import-dialog")}
            style={{
              borderRight: "1px solid rgba(138, 187, 255, 0.6)",
            }}
          >
            Import...
          </Button>
          <Popover content={dropdownMenu} position={Position.BOTTOM} minimal>
            <Button icon="plus" rightIcon="caret-down" />
          </Popover>
          {/* <Button icon="notifications" /> */}
          <Popover content={dropdownUser} position={Position.BOTTOM} minimal>
            <Button
              icon={
                user?.ProfileImageS3Url ? (
                  <img src={user.ProfileImageS3Url} className="h-6 w-6 rounded-full" alt={user.FirstName} />
                ) : (
                  "user"
                )
              }
              rightIcon="caret-down"
            />
          </Popover>
          {/* <Button icon="help" /> */}
        </ButtonGroup>
      </Navbar.Group>
    </Navbar>
  );
};

export default NavbarComponent;
