import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { binDataForHistogram } from "../../../data-transformers/returns-to-bin-data";
import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../store/global";
import { findPercentile } from "../../../utils/findPercentile";
import { findStringWithFloat } from "../../../utils/findStringWithFloat";

export const useTransformValueAtRisk = () => {
  const rawDataQuery = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount).id;
  const queryFn = useCallback(async () => {
    if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
      throw new Error("Raw data is not available");
    }

    return binDataForHistogram(rawDataQuery.data.results.returns?.data[selectedPortfolio] || []);
  }, [rawDataQuery, selectedPortfolio]);

  const transformedValueAtRiskData = useQuery({
    queryKey: [
      "transformed",
      {
        type: "ValueAtRisk",
        selectedPortfolio,
        dataUpdatedAt: rawDataQuery.dataUpdatedAt,
      },
    ],
    queryFn,
    enabled: rawDataQuery.isSuccess,
    staleTime: Infinity,
  });

  // Calculate the fifth percentile for VAR
  const fifthPercentileForVar = useCallback(() => {
    return findPercentile(rawDataQuery.data?.results?.returns?.data[selectedPortfolio] || [], 5);
  }, [rawDataQuery.data?.results?.returns?.data, selectedPortfolio]);

  // Find the bin with VAR
  const binWithVar = useCallback(() => {
    return findStringWithFloat(fifthPercentileForVar(), transformedValueAtRiskData?.data?.results?.categories || []);
  }, [fifthPercentileForVar, transformedValueAtRiskData]);

  // Call the callbacks to get the values
  const binWithVarValue = binWithVar();

  return {
    binWithVarValue,
    transformedValueAtRiskData,
  };
};
