import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { transformVolatilityToMonthDataSeries } from "../../../../data-transformers/volatility-to-month-series-data";
import { usePortfolioStatsAll } from "../../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../../store/global";

export const useTransformVolatilityToMonthData = (isInView: boolean) => {
  const rawDataQuery = usePortfolioStatsAll(); // Assume this returns a query object
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount).id;
  const queryFn = useCallback(async () => {
    if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
      throw new Error("Raw data is not available");
    }

    return transformVolatilityToMonthDataSeries(
      rawDataQuery.data.results.rollingAnnualVolatility?.data[selectedPortfolio] || [],
    );
  }, [rawDataQuery, selectedPortfolio]);

  return useQuery({
    queryKey: [
      "transformed",
      {
        type: "VolatilityToMonthData",
        selectedPortfolio,
        dataUpdatedAt: rawDataQuery.dataUpdatedAt,
      },
    ],
    queryFn,
    enabled: rawDataQuery.isSuccess && isInView,
    staleTime: Infinity,
  });
};
