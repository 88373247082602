const SkeletonHorizontalChart = () => {
  return (
    <div role="status" className="h-[415px] w-full animate-pulse rounded p-4 shadow dark:border-gray-700">
      <div className="mb-4 h-2.5 w-32 rounded-full bg-gray-600 dark:bg-gray-700"></div>
      <div className="mb-10 h-2 w-48 rounded-full bg-gray-600 dark:bg-gray-700"></div>
      <div className="flex flex-col space-y-4">
        <div className="h-8 w-[60%] rounded-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="h-8 w-[40%] rounded-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="h-8 w-[80%] rounded-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="h-8 w-[60%] rounded-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="h-8 w-[40%] rounded-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="h-8 w-[80%] rounded-lg bg-gray-600 dark:bg-gray-700"></div>
      </div>
    </div>
  );
};

export default SkeletonHorizontalChart;
