import { create } from "zustand";

export interface IPortfolio {
  id: string;
  accountAlias: string;
  description: string;
  benchmarkSymbol?: string;
  capitalBase?: number;
}
interface IEditPortfolioStoreState {
  portfolio: IPortfolio;
  updatePortfolio: (portfolio: IPortfolio) => void;
  resetStore: () => void;
}
const initialStatePortfolio: IPortfolio = {
  id: "",
  accountAlias: "",
  benchmarkSymbol: "",
  description: "",
  capitalBase: 0,
};

export const useEditPortfolioStore = create<IEditPortfolioStoreState>((set) => ({
  portfolio: initialStatePortfolio,
  updatePortfolio: (portfolio: IPortfolio) => set({ portfolio }),
  resetStore: () => set({ portfolio: initialStatePortfolio }),
}));
