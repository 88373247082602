import { BlueprintProvider } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import dotenv from "dotenv";
import "normalize.css";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

dotenv.config();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BlueprintProvider>
      <App />
    </BlueprintProvider>
  </React.StrictMode>,
);

reportWebVitals(console.log);
