import { Alignment, Button, Navbar } from "@blueprintjs/core";
import { TotalPortfolioValues } from "@tradeblotter/tradeblotter-api";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback } from "react";

import { useDeletePortfolioStore } from "../components/PortfolioDialogs/DeletePortfolioDialog/useDeletePortfolioStore";
import {
  IPortfolio,
  useEditPortfolioStore,
} from "../components/PortfolioDialogs/EditPortfolioDialog/useEditPortfolioStore";
import SkeletonTable from "../components/Skeletons/SkeletonTable";
import { useMyAccounts } from "../hooks/useMyAccounts";
import { usePortfolioStatsAll } from "../hooks/usePortfolioStats";
import { useDialog } from "../providers/DialogContext";
import { currencyFormat } from "../utils/currencyFormat";
import { scalarFormatter } from "../utils/formatters";

// const pagination = true,
//     paginationPageSize = 10,
//     paginationPageSizeSelector = [10, 20, 50, 100];

const typeSize = "fitGridWidth" as const;
const autoSizeStrategy = {
  type: typeSize,
  defaultMinWidth: 100,
  columnLimits: [
    {
      colId: "country",
      minWidth: 900,
    },
  ],
};

const cellClassRules = {
  // apply green to electric cars
  "rag-green": (params: { value: number }) => params.value > 0,
  "rag-red": (params: { value: number }) => params.value < 0,
};

const Portfolios = () => {
  const { openDialog } = useDialog();

  const handleMenuItemClick = (option: string) => {
    openDialog(option);
  };

  const accounts = useMyAccounts();
  const accountsData = accounts?.data?.results;
  const { data: portfolioStats, isLoading } = usePortfolioStatsAll();
  const totalPortfolioValues = portfolioStats?.results?.totalPortfolioValues?.data;

  // Merge accountsData with totalPortfolioValue
  const portfolioData = accountsData?.map((account) => {
    return {
      ...account,
      ...extractSelectedPortfolio(totalPortfolioValues || ({} as TotalPortfolioValues), account.id),
    };
  });

  return (
    <div className="panel transactions-panel">
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Button
            className="bp5-minimal"
            icon="add"
            text="Create portfolio"
            intent="primary"
            onClick={() => handleMenuItemClick("create-portfolio-dialog")}
          />
          {/* @TODO: Add more actions */}
          {/* <Button
                        className="bp5-minimal"
                        icon="export"
                        text="Export to CSV"
                    /> */}
          {/* <Button
                        className="bp5-minimal"
                        icon="duplicate"
                        text="Duplicate"
                    /> */}
        </Navbar.Group>
      </Navbar>
      <div
        className="ag-theme-balham-auto-dark" // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
        {isLoading ? (
          <div className="border border-[#585D62]">
            <SkeletonTable />
          </div>
        ) : (
          <AgGridReact
            // pagination={pagination}
            // paginationPageSize={paginationPageSize}
            // paginationPageSizeSelector={paginationPageSizeSelector}
            rowData={portfolioData}
            // @ts-expect-error
            columnDefs={colDefs}
            autoSizeStrategy={autoSizeStrategy}
          />
        )}
      </div>
    </div>
  );
};

const extractSelectedPortfolio = (data: TotalPortfolioValues, selectedPortfolio: string) => {
  const result: Record<string, any> = {};
  for (const key of Object.keys(data)) {
    if (data[key as keyof TotalPortfolioValues]?.[selectedPortfolio]) {
      result[key] = data[key as keyof TotalPortfolioValues][selectedPortfolio];
    }
  }

  return result;
};

const EditButton = React.memo((props: { data: IPortfolio }) => {
  const { id, accountAlias, benchmarkSymbol, description, capitalBase } = props.data;
  const { openDialog } = useDialog();
  const { updatePortfolio } = useEditPortfolioStore();

  const handleClick = useCallback(() => {
    updatePortfolio({
      id,
      accountAlias,
      benchmarkSymbol,
      description,
      capitalBase,
    });
    openDialog("edit-portfolio-dialog");
  }, [accountAlias, description, id, openDialog, updatePortfolio, benchmarkSymbol, capitalBase]);

  if (!id) return null;

  return <Button icon="edit" minimal small onClick={handleClick} />;
});

const DeleteButton = (props: { index: string }) => {
  const { openDialog } = useDialog();
  const { updatePortfolioId } = useDeletePortfolioStore();

  const handleDeleteClick = (index: string) => {
    updatePortfolioId(index);
    openDialog("delete-portfolio-dialog");
  };

  return (
    <Button
      icon="trash"
      minimal
      small
      onClick={() => {
        handleDeleteClick(props.index);
      }}
    />
  );
};

const colDefs = [
  { field: "accountAlias", headerName: "Portfolio" },
  {
    field: "benchmarkSymbol",
    headerName: "Benchmark",
  },
  { field: "capitalBase", headerName: "Total holdings" },
  // {
  //     field: "totalValue",
  //     valueFormatter: (p: { value: number }) => {
  //         return p.value > 0 ? "$" + p.value.toLocaleString() : p.value;
  //     },
  // },
  // {
  //     field: "pnl",
  //     cellClassRules: cellClassRules,
  //     valueFormatter: (p: { value: number }) => {
  //         return p.value > 0 ? "$" + p.value.toLocaleString() : p.value;
  //     },
  // },
  {
    field: "investedDollar",
    headerName: "Invested",
    valueFormatter: (p: { value: number }) => {
      return currencyFormat(Number(p.value));
    },
  },
  {
    field: "cashDollar",
    headerName: "Cash",
    valueFormatter: (p: { value: number }) => {
      return currencyFormat(Number(p.value));
    },
  },
  {
    field: "sharpeRatio",
    cellClassRules: cellClassRules,
    valueFormatter: (p: { value: number }) => {
      return scalarFormatter(Number(p.value), 2, 2);
    },
  },
  {
    field: "varDollar",
    headerName: "VaR",
    valueFormatter: (p: { value: number }) => {
      return currencyFormat(Number(p.value));
    },
  },
  {
    field: "sortinoRatio",
    headerName: "Sortino",
    valueFormatter: (p: { value: number }) => {
      return scalarFormatter(Number(p.value), 2, 2);
    },
  },
  {
    field: "totalReturnDollar",
    headerName: "Total return",
    valueFormatter: (p: { value: number }) => {
      return currencyFormat(Number(p.value));
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    cellRenderer: (params: { value: string }) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 70,
    cellRenderer: (params: { data: IPortfolio }) => {
      return <EditButton data={params.data} />;
    },
    autoHeight: true,
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 70,
    cellRenderer: (params: { data: IPortfolio }) => {
      return <DeleteButton index={params.data.id} />;
    },
    autoHeight: true,
  },
];

export default Portfolios;
