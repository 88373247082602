// src/pages/Dashboard.js
import { Section } from "@blueprintjs/core";
import React from "react";

import LineChart from "../components/Charts/LineChart";
import DateSelector from "../components/DateSelector";
import IndexSelector from "../components/IndexSelector";

const RollingBeta = () => {
  const data = [
    {
      name: "Rolling beta to index",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];

  const categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"];

  return (
    <Section
      title={
        <>
          Rolling beta versus <IndexSelector />
        </>
      }
      subtitle="This chart illustrates the 6-month rolling, annualized daily volatility of the portfolio."
      rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      <LineChart data={data} categories={categories} height={"400"} />
    </Section>
  );
};

const Factor = () => {
  return (
    <div>
      <RollingBeta />
    </div>
  );
};

export default Factor;
