import { Card } from "../../../@/components/ui/card";

const SkeletonCard = () => {
  return (
    <Card>
      <div className="h-[260px] animate-pulse p-6 shadow">
        <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-600 dark:bg-gray-700"></div>
        <div className="mb-4 h-2 rounded-full bg-gray-600 dark:bg-gray-700"></div>

        <div className="h-2 rounded-full bg-gray-600 dark:bg-gray-700"></div>
        {/* <div className="flex items-center mt-4">
                    <div>
                        <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-32 mb-4"></div>
                        <div className="w-48 h-2 bg-gray-600 rounded-full dark:bg-gray-700"></div>
                    </div>
                </div> */}
        <SkeletonVerticalChartForCard />
      </div>
    </Card>
  );
};

export const SkeletonVerticalChartForCard = () => {
  return (
    <div className="mt-24 flex items-baseline">
      <div className="h-14 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-24 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-14 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-24 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-14 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-24 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-14 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-24 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-14 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      <div className="ms-3 h-24 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
    </div>
  );
};

export default SkeletonCard;
