import _ from "lodash";
import Chart from "react-apexcharts";

interface IAreaChartProps {
  data: ApexCharts.ApexOptions["series"];
  options?: ApexCharts.ApexOptions;
  categories?: string[];
  height?: number;
}

const AreaChart = (props: IAreaChartProps) => {
  const { data, categories = [], height = 400, options = {} } = props;

  const baseOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: true,
      },
    },
    // This one produces an error, not sure why it was being applied
    // annotations: {
    //     yaxis: [{
    //         y: 0,
    //     }]
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1,
    },
    grid: {
      show: true,
      borderColor: "#585d63",
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: "#f4f5f7",
        },
      },
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      tickAmount: 4,
      floating: false,
      labels: {
        style: {
          colors: "#f4f5f7",
        },
      },
      axisBorder: {
        show: true,
      },
    },
    tooltip: {
      theme: "dark",
    },
    fill: {
      opacity: 1,
      colors: ["#E76A6E"],
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      offsetX: 40,
      labels: {
        colors: "#f4f5f7",
      },
    },
    noData: {
      text: "No data available",
      style: {
        fontSize: "14px",
        color: "#ffffff",
      },
    },
  };

  if (categories?.length && baseOptions.xaxis?.categories) {
    baseOptions.xaxis.categories = categories;
  }
  if (height && baseOptions.chart?.height) {
    baseOptions.chart.height = height;
  }

  return <Chart options={_.merge(baseOptions, options)} series={data} height={height} type="area" />;
};

export default AreaChart;
