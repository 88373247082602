import { AccountResponseModel } from "@tradeblotter/tradeblotter-api";
import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SuggestPortfolio from "../components/SuggestPortfolio";
import { useMultiSelect } from "../providers/MultiSelectContext";
import { useGlobalStore } from "../store/global";

// Lazy load the dialog components
const NewPortfolioDialog = lazy(() =>
  import("../components/PortfolioDialogs/NewPortfolioDialog").then((module) => ({
    default: module.NewPortfolioDialog,
  })),
);
const EditPortfolioDialog = lazy(() =>
  import("../components/PortfolioDialogs/EditPortfolioDialog").then((module) => ({
    default: module.EditPortfolioDialog,
  })),
);
const DeletePortfolioDialog = lazy(() =>
  import("../components/PortfolioDialogs/DeletePortfolioDialog").then((module) => ({
    default: module.DeletePortfolioDialog,
  })),
);
const NewAlertDialog = lazy(() =>
  import("../components/NewAlertDialog").then((module) => ({
    default: module.NewAlertDialog,
  })),
);

// @NOTE: This is not being used right now but if required it need to be converted to tsx
// const NewAllocationDialog = lazy(() =>
//     import("../components/NewAllocationDialog").then((module) => ({
//         default: module.NewAllocationDialog,
//     }))
// );
const NewTransactionDialog = lazy(() =>
  import("../components/NewTransactionDialog").then((module) => ({
    default: module.NewTransactionDialog,
  })),
);
const AdjustCashDialog = lazy(() =>
  import("../components/AdustCashDialog").then((module) => ({
    default: module.AdjustCashDialog,
  })),
);
const ImportDialog = lazy(() =>
  import("../components/ImportDialog").then((module) => ({
    default: module.ImportDialog,
  })),
);

const NewApiKeyDialog = lazy(() =>
  import("../components/NewApiKeyDialog").then((module) => ({
    default: module.NewApiKeyDialog,
  })),
);

const MainLoggedInLayout = () => {
  const { isMultiSelectEnabled } = useMultiSelect();
  const { selectedAccount } = useGlobalStore((state) => state);
  const updateSelectedAccount = useGlobalStore((state) => state.updateSelectedAccount);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Navbar />
      <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <Sidebar />
        <main style={{ flex: 1, padding: "20px", overflowY: "auto" }}>
          {isMultiSelectEnabled && (
            <div style={{ width: "25%", marginBottom: "20px" }}>
              <SuggestPortfolio
                enableAllSelection={true}
                selectedPortfolio={selectedAccount.accountAlias}
                setValue={({ accountAlias, brokerageId, id }: AccountResponseModel) => {
                  updateSelectedAccount({
                    accountAlias,
                    brokerageId,
                    id,
                  });
                }}
              />
            </div>
          )}
          <Outlet />
        </main>
      </div>

      {/* Use Suspense to wrap the lazy-loaded dialogs */}
      <Suspense fallback={<div>Loading...</div>}>
        <ImportDialog />
        <NewPortfolioDialog />
        <EditPortfolioDialog />
        <DeletePortfolioDialog />
        <NewAlertDialog />
        {/* <NewAllocationDialog /> */}
        <NewApiKeyDialog />
        <NewTransactionDialog />
        <AdjustCashDialog />
      </Suspense>
    </div>
  );
};

export default MainLoggedInLayout;
