import React, { ReactNode, createContext, useContext, useState } from "react";

interface DialogContextType {
  openDialog: (dialogId: string) => void;
  closeDialog: (dialogId: string) => void;
  isDialogOpen: (dialogId: string) => boolean;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [openDialogs, setOpenDialogs] = useState<Set<string>>(new Set());

  const openDialog = (dialogId: string) => {
    setOpenDialogs((prev) => new Set(prev).add(dialogId));
  };

  const closeDialog = (dialogId: string) => {
    setOpenDialogs((prev) => {
      const newSet = new Set(prev);
      newSet.delete(dialogId);
      return newSet;
    });
  };

  const isDialogOpen = (dialogId: string) => openDialogs.has(dialogId);
  return <DialogContext.Provider value={{ openDialog, closeDialog, isDialogOpen }}>{children}</DialogContext.Provider>;
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
