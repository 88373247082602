import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { transformSharpeRatioToMonthDataSeries } from "../../../data-transformers/sharpe-ratio-to-month-series-data";
import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../store/global";

export const useTransformSharpeRatio = () => {
  const rawDataQuery = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount).id;

  const queryFn = useCallback(async () => {
    if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
      throw new Error("Raw data is not available");
    }

    return transformSharpeRatioToMonthDataSeries(
      rawDataQuery.data.results.rollingSharpeRatio?.data[selectedPortfolio] || [],
    );
  }, [rawDataQuery, selectedPortfolio]);
  return useQuery({
    queryKey: [
      "transformed",
      {
        type: "SharpeRatioData",
        selectedPortfolio,
        dataUpdatedAt: rawDataQuery.dataUpdatedAt,
      },
    ],
    queryFn,
    enabled: rawDataQuery.isSuccess,
    staleTime: Infinity,
  });
};
