import { Colors, Section } from "@blueprintjs/core";

import { Card } from "../@/components/ui/card";
import PortfolioValueChart from "../components/Dashboard/PortfolioValueChart";
import SharpeRatioChart from "../components/Dashboard/SharpeRatioChart";
import TotalReturnChart from "../components/Dashboard/TotalReturnChart";
import ValueAtRiskChart from "../components/Dashboard/ValueAtRiskChart";
import TooltipWithIcon from "../components/TooltipWithIcon";
import ChartContainer from "../components/containers/ChartContainer";
import { Allocation } from "../components/widgets/Allocation";
import { Drawdown } from "../components/widgets/Drawdown";
import { HistoricAllocation } from "../components/widgets/HistoricAllocation";
import { PortfolioPerformance } from "../components/widgets/PortfolioPerformance";
import { PositionStats } from "../components/widgets/PositionsStats";
import { Volatility } from "../components/widgets/Volatility";
import { useMyAccounts } from "../hooks/useMyAccounts";
import { usePortfolioStatsAll } from "../hooks/usePortfolioStats";
// import DateSelector from "../components/DateSelector";
import { useGlobalStore } from "../store/global";
import { currencyFormat } from "../utils/currencyFormat";
import { percentageFormat } from "../utils/percentageFormat";
import { toMax2DigitsFormat, toMaxSelectedDigitsFormat } from "../utils/toMax2DigitsFormat";

const PortfolioSummary = () => {
  const { data, isError } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);
  const myAccounts = useMyAccounts();
  const myAccountsLength = myAccounts?.data?.results?.length || 0;

  const selectedAccount = selectedPortfolio.id || "portfolio";

  if (isError) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="col-span-1 flex h-full items-center justify-center">
          <div className="text-center">
            <h1 className="text-lg font-medium">Error</h1>
            <p className="text-xs text-muted-foreground">
              <span
                style={{
                  color: Colors.RED4,
                }}
              >
                There was an error loading your portfolio. Please reach out to support for assistance.
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  const portfolioValue = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.portfolioValue[selectedAccount]);
  const portfolioDollarChange = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.portfolioChangeDollar[selectedAccount],
  );
  const portfolioPercentChange = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.portfolioChangePercentage[selectedAccount],
  );

  const totalReturn = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.totalReturnPercentage[selectedAccount],
  );
  const totalReturnDollar = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.totalReturnDollar[selectedAccount],
  );

  const ytdReturn = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.ytdReturnPercentage[selectedAccount]);
  const ytdReturnDollar = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.ytdReturnDollar[selectedAccount],
  );

  const varDollar = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.varDollar[selectedAccount]);
  const varPercent = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.varPercentage[selectedAccount]);
  const cagr = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.cagr[selectedAccount]);
  const sharpeRatio = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.sharpeRatio[selectedAccount]);
  const sharpeRatioPercentChange = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.sharpeRatioPercentage[selectedAccount],
  );

  const investedDollar = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.investedDollar[selectedAccount]);
  const investedPercent = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.investedPercentage[selectedAccount],
  );
  const cashDollar = toMax2DigitsFormat(data?.results?.totalPortfolioValues?.data?.cashDollar[selectedAccount]);

  const varChangeDollar = toMax2DigitsFormat(
    data?.results?.totalPortfolioValues?.data?.varChangeDollar[selectedAccount],
  );
  const varChangePercent = toMaxSelectedDigitsFormat(
    data?.results?.totalPortfolioValues?.data?.varChangePercentage[selectedAccount],
    4,
  );

  return (
    <Section
      title={
        <TooltipWithIcon
          title={
            selectedAccount !== "portfolio"
              ? `Total (${myAccountsLength} portfolio${myAccountsLength > 1 ? "s" : ""})`
              : "Portfolio summary"
          }
          tip="This summary shows the aggregate risk and performance metrics across all portfolios."
        />
      }
      // rightElement={<DateSelector />}
      style={{ marginBottom: "20px" }}
    >
      <div className="grid gap-4 p-4 md:grid-cols-2 lg:grid-cols-5">
        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon
                title="Portfolio value"
                tip="Total value of long exposure less short exposure plus cash."
              />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div className="text-2xl font-bold text-white">{currencyFormat(portfolioValue)}</div>
            <p className="text-xs text-muted-foreground">
              <span
                style={{
                  color: Number(portfolioDollarChange) < 0 ? Colors.RED4 : Colors.GREEN4,
                }}
              >
                {`${currencyFormat(portfolioDollarChange)} (${percentageFormat(portfolioPercentChange)})`}
              </span>{" "}
              {portfolioDollarChange !== "N/A" ? "since yesterday" : "No data available"}
            </p>
            <div>
              <PortfolioValueChart />
            </div>
          </div>
        </Card>

        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Total return" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold text-white"
              style={{
                color: Number(totalReturnDollar.replace(/,/g, "")) < 0 ? Colors.RED4 : Colors.GREEN4,
              }}
            >
              {`${currencyFormat(totalReturnDollar)} (${percentageFormat(totalReturn)})`}
            </div>
            <p className="text-xs text-muted-foreground">
              <span
                style={{
                  color: Number(ytdReturnDollar) < 0 ? Colors.RED4 : Colors.GREEN4,
                }}
              >
                {`${currencyFormat(ytdReturnDollar)} (${percentageFormat(ytdReturn)})`}
              </span>{" "}
              {ytdReturnDollar !== "N/A" ? "year to date" : "No data available"}
            </p>
            <div>
              <TotalReturnChart />
            </div>
          </div>
        </Card>

        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Value at risk" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div className="text-2xl font-bold text-white">
              {`${currencyFormat(varDollar)} (${percentageFormat(varPercent)})`}
            </div>
            <p className="text-xs text-muted-foreground">
              <span
                style={{
                  color: Number(varChangePercent) < 0 ? Colors.GREEN4 : Colors.RED4,
                }}
              >
                {currencyFormat(varChangeDollar)} ({varChangePercent}%)
              </span>{" "}
              {ytdReturnDollar !== "N/A" ? "since yesterday" : "No data available"}
            </p>
            <div>
              <ValueAtRiskChart />
            </div>
          </div>
        </Card>

        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Sharpe ratio" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div className="text-2xl font-bold text-white">{`${sharpeRatio}`}</div>
            <p className="text-xs text-muted-foreground">
              <span
                style={{
                  color: Number(sharpeRatioPercentChange) < 0 ? Colors.RED4 : Colors.GREEN4,
                }}
              >
                {`${percentageFormat(sharpeRatioPercentChange)}`}
              </span>{" "}
              {sharpeRatio !== "N/A" ? "since yesterday" : "No data available"}
            </p>
            <div>
              <SharpeRatioChart />
            </div>
          </div>
        </Card>

        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-1">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="CAGR" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold text-white"
              style={{
                color: Number(cagr) < 0 ? Colors.RED4 : Colors.GREEN4,
              }}
            >
              {percentageFormat(cagr)}
            </div>
          </div>

          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-1">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Securities Invested" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div className="text-2xl font-bold text-white">
              {`${currencyFormat(investedDollar)} (${percentageFormat(investedPercent)})`}
            </div>
          </div>

          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-1">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Cash available" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div className="text-2xl font-bold text-white">
              {`${currencyFormat(cashDollar)} (${percentageFormat(1 - Number(investedPercent))})`}
            </div>
          </div>
        </Card>
      </div>
    </Section>
  );
};

const Dashboard = () => {
  return (
    <div>
      <PortfolioSummary />
      <PositionStats />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px", // This adds space between the cards
          flexWrap: "wrap", // This allows cards to wrap to next line on smaller screens
        }}
      >
        {/* Position weight allocation */}
        <ChartContainer>
          <Allocation id={"allocation-dashboard"} />
        </ChartContainer>

        <ChartContainer>
          <HistoricAllocation id={"historic-allocation-dashboard"} />
        </ChartContainer>
      </div>
      <PortfolioPerformance id={"portfolio-performance-dashboard"} />
      <Volatility id={"volatility-dashboard"} />
      <Drawdown id={"drawdown-dashboard"} />
    </div>
  );
};

export default Dashboard;
