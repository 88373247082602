import _ from "lodash";
import Chart from "react-apexcharts";

interface IHeatmapChartProps {
  data: ApexCharts.ApexOptions["series"];
  height?: number;
  options?: ApexCharts.ApexOptions;
}

const HeatmapChart = (props: IHeatmapChartProps) => {
  const { data, height = 400, options } = props;

  const baseOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "heatmap",
    },
    plotOptions: {
      heatmap: {
        radius: 0,
        useFillColorAsStroke: true,
        reverseNegativeShade: true,
        enableShades: true,
        colorScale: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: "#E76A6E",
            },
            {
              from: 0,
              to: Infinity,
              color: "#32A467",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    grid: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#f4f5f7",
        },
      },
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#f4f5f7",
        },
      },
      axisBorder: {
        show: true,
      },
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No data available",
      style: {
        fontSize: "14px",
        color: "#ffffff",
      },
    },
  };

  return <Chart options={_.merge(baseOptions, options)} series={data} height={height} type="heatmap" />;
};

export default HeatmapChart;
