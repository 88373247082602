import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../api/client";

interface IPortfolioStatsRequest {
  id?: string;
  startDate?: string;
  endDate?: string;
  range?: string;
}

export const usePositionTableStats = (props?: IPortfolioStatsRequest) => {
  return useQuery({
    queryKey: ["positionOverviewStats"],
    queryFn: () => fetchPositionTableStats(),
    staleTime: Infinity,
    refetchOnMount: true,
  });
};

export const fetchPositionTableStats = async (props?: {
  symbol?: string;
  startDate?: string;
  end_date?: string;
  range?: string;
}) => {
  try {
    const response = await apiClient.api.getPositionsTableStats();
    return response;
  } catch (error) {
    console.error("Error in fetchPositionTableStats:", (error as Error).message);
    throw error;
  }
};
