import { Section } from "@blueprintjs/core";

import TooltipWithIcon from "../../TooltipWithIcon";
// import DateSelector from "../../DateSelector";
import PortfolioPerformance from "./PortfolioPerformance";

const PortfolioPerformanceContainer = (props: { id: string }) => {
  return (
    <Section
      title={<TooltipWithIcon title="Portfolio performance" tip="..." />}
      // rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      <PortfolioPerformance id={props.id} />
    </Section>
  );
};

export default PortfolioPerformanceContainer;
