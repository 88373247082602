import { scalarFormatter } from "./formatters";

export const toMax2DigitsFormat = (value?: number | string): string => {
  const num = Number(value);
  if (isNaN(num)) return "N/A";
  return scalarFormatter(num, 2, 2);
};

export const toMaxSelectedDigitsFormat = (value?: number | string, decimals = 4): string => {
  const num = Number(value);
  if (isNaN(num)) return "N/A";
  return scalarFormatter(num, decimals, decimals);
};
