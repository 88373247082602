export const currencyFormat = (value?: number | string, fractionDigits?: number): string => {
  const numericValue = typeof value === "string" ? value.replace(/,/g, "") : value;
  const num = Number(numericValue);

  if (isNaN(num)) return "N/A";

  const absValue = Math.abs(num).toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits ?? 2, // Ensures at least 2 decimals
    maximumFractionDigits: fractionDigits ?? 2, // Limits to at most 2 decimals
  });
  const result = num < 0 ? `-$${absValue}` : `$${absValue}`;
  return result;
};
