import { Icon } from "@blueprintjs/core";

import { Button } from "../../@/components/ui/button";

const ThankYouPageComponent = () => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 text-center">
      <h1 className="text-4xl font-bold md:text-5xl">
        Great, now check <br className="hidden sm:block" /> your inbox
      </h1>
      <h3 className="mt-4 max-w-md text-lg text-gray-300">
        We just sent you an email to complete the sign-up process & set your password. Please check your spam folder if
        you haven’t received it within a few minutes.
      </h3>
      <Button
        className="mt-8 flex items-center gap-2 rounded-md px-6 py-2 font-medium shadow transition hover:bg-gray-800"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <span className="ml-1">
          <Icon icon="arrow-left" />
        </span>
        Go back
      </Button>
    </div>
  );
};

export default ThankYouPageComponent;
