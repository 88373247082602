import { OverlayToaster, OverlayToasterProps } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";

const toasterProps: OverlayToasterProps = {
  position: "top-right",
  maxToasts: 5,
};

export const toaster = await OverlayToaster.createAsync(toasterProps, {
  // Use createRoot() instead of ReactDOM.render(). This can be deleted after
  // a future Blueprint version uses createRoot() for Toasters by default.
  domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster),
});
