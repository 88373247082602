import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../api/client";

interface ITransactionsRequest {
  id?: string;
  brokerageId?: string;
  accountId?: string;
  startDate?: string;
  endDate?: string;
  shouldUseDemoData?: boolean;
}

const useTransactions = ({ id, brokerageId, accountId, startDate, endDate }: ITransactionsRequest) => {
  const queryKey = ["transactions"];
  if (id) queryKey.push(...["symbol", id]);
  if (brokerageId) queryKey.push(...["brokerage", brokerageId]);
  if (accountId) queryKey.push(...["account", accountId]);
  if (startDate) queryKey.push(...["startDate", startDate]);
  if (endDate) queryKey.push(...["endDate", endDate]);
  return useQuery({
    queryKey: queryKey,
    queryFn: async () =>
      await apiClient.api.getTransactions({
        brokerageId,
        accountId,
        startDate,
        endDate,
      }),
    staleTime: Infinity,
  });
};

export { useTransactions };
