import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMultiSelect } from "../providers/MultiSelectContext";

const Symbol = () => {
  const { symbolId } = useParams();

  const { disableMultiSelect } = useMultiSelect();
  useEffect(() => {
    disableMultiSelect();
  }, [disableMultiSelect]);
  return (
    <div>
      <h1>Symbol {symbolId}</h1>
    </div>
  );
};

export default Symbol;
