import { Section } from "@blueprintjs/core";

import AreaChart from "../components/Charts/AreaChart";
import DateSelector from "../components/DateSelector";
import { transformPortfolioValueToMonthDataSeries } from "../data-transformers/portfolio-value-to-month-series-data";
import { usePortfolioStatsAll } from "../hooks/usePortfolioStats";
import { useGlobalStore } from "../store/global";
import { percentageFormatter } from "../utils/formatters";

const Leverage = () => {
  const { data, isLoading } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);

  // Transform data from {d, v} to {x, y}
  const transformedData = transformPortfolioValueToMonthDataSeries(
    data?.results?.leverage?.data[selectedPortfolio.id] || [],
  );

  return (
    <Section
      title="Portfolio leverage"
      subtitle="Leverage represents the percentage of invested assets relative to cash available. For example, if your leverage is 150%, that means you have $1.50 worth of investments for every $1 of your own money. The other $0.50 is borrowed from your broker."
      rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      {isLoading ? (
        <div className="flex h-48 w-full items-center justify-center">
          <p>No data available</p>
        </div>
      ) : (
        <AreaChart
          data={transformedData.data.y || []}
          categories={transformedData.data.x || []}
          height={400}
          options={{
            xaxis: {
              type: "datetime",
            },
            yaxis: {
              labels: {
                formatter: (value) =>
                  percentageFormatter(Number(value) / 10, {
                    maximumFractionDigits: 0,
                  }),
              },
            },
          }}
        />
      )}
    </Section>
  );
};

export default Leverage;
