import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { transformDrawdownToMonthDataSeries } from "../../../../data-transformers/drawdown-to-month-series-data";
import { usePortfolioStatsAll } from "../../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../../store/global";

export const useTransformDrawdownToMonthData = () => {
  const rawDataQuery = usePortfolioStatsAll(); // Assume this returns a query object
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount).id;
  const queryFn = useCallback(async () => {
    if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
      throw new Error("Raw data is not available");
    }

    return transformDrawdownToMonthDataSeries(
      rawDataQuery.data.results.rollingMaxDrawdown?.data[selectedPortfolio] || [],
    );
  }, [rawDataQuery.data, rawDataQuery.isSuccess, selectedPortfolio]);

  return useQuery({
    queryKey: [
      "transformed",
      {
        type: "DrawdownToMonthData",
        selectedPortfolio,
        dataUpdatedAt: rawDataQuery.dataUpdatedAt,
      },
    ],
    queryFn,
    staleTime: Infinity,
  });
};
