export const percentageFormat = (value?: number | string, fractionDigits?: number): string => {
  const num = Number(value);
  if (isNaN(num)) return "N/A";

  // Ensure at least 1 decimal place
  const digits = fractionDigits ?? 1;

  return `${(num * 100).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: digits,
  })}%`;
};
