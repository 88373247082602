import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../store/global";
import { percentageFormatter } from "../../../utils/formatters";
import HorizontalStackedBarChart from "../../Charts/HorizontalBarChart";
import SkeletonHorizontalChart from "../../Charts/placeholders/SkeletonHorizontalChart";
import { useTransformAllocationData } from "./hooks/useTransformAllocationData";

const Allocation = (props: { id: string }) => {
  const { isLoading: isLoadingPortfolioStats } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);
  const { data: transformedData, isLoading } = useTransformAllocationData(selectedPortfolio.id);

  if (isLoadingPortfolioStats || isLoading) {
    return <SkeletonHorizontalChart />;
  }

  return (
    <HorizontalStackedBarChart
      data={transformedData?.transformedData || []}
      categories={transformedData?.symbols || []}
      options={{
        chart: {
          id: props.id,
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return percentageFormatter(Number(value) / 100);
            },
          },
        },
        dataLabels: {
          formatter: function (val) {
            return percentageFormatter(Number(val) / 100);
          },
        },
      }}
    />
  );
};

export default Allocation;
