import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Import useLocation to get the current route

interface MultiSelectContextType {
  isMultiSelectEnabled: boolean;
  enableMultiSelect: () => void;
  disableMultiSelect: () => void;
}

const MultiSelectContext = createContext<MultiSelectContextType>({
  isMultiSelectEnabled: false,
  enableMultiSelect: () => {},
  disableMultiSelect: () => {},
});

interface IMultiSelectProviderProps {
  children: React.ReactNode;
  routesWithMultiSelect?: string[];
}

export const MultiSelectProvider = ({
  children,
  routesWithMultiSelect = [
    "/",
    "/dashboard",
    "/holdings",
    "/allocation",
    "/transactions",
    "/equity",
    "/rollingPerformance",
    "/returns",
    "/drawdown",
    "/rollingRisk",
    "/leverage",
  ],
}: IMultiSelectProviderProps) => {
  const [isMultiSelectEnabled, setMultiSelectEnabled] = useState(false);
  const location = useLocation(); // Get the current route
  const enableMultiSelect = () => setMultiSelectEnabled(true);
  const disableMultiSelect = () => setMultiSelectEnabled(false);

  // Automatically enable/disable multi-select based on the current route
  useEffect(() => {
    if (routesWithMultiSelect.includes(location.pathname)) {
      enableMultiSelect();
    } else {
      disableMultiSelect();
    }
  }, [location.pathname, routesWithMultiSelect]);

  return (
    <MultiSelectContext.Provider
      value={{
        isMultiSelectEnabled,
        enableMultiSelect,
        disableMultiSelect,
      }}
    >
      {children}
    </MultiSelectContext.Provider>
  );
};

export const useMultiSelect = () => useContext(MultiSelectContext);
