import * as React from "react";

import { useAuth } from "../auth/useAuth";
import { LoadingSpinner } from "../components/LoadingSpinner";
import MainLoggedInLayout from "./MainLoggedInLayout";

export const MainLoggedOut: React.FC = () => {
  return (
    <>
      <div className="flex h-full items-center justify-center">
        <div className="w-full" id="login-embed"></div>
      </div>
    </>
  );
};

export const Main: React.FC = () => {
  const { isLoading, user } = useAuth();
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (user) {
    return <MainLoggedInLayout />;
  }

  return <MainLoggedOut />;
};
