export function findStringWithFloat(float: number, ranges: string[]) {
  for (const [index, range] of Array.from(ranges.entries())) {
    const [start, end] = range.split(" to ").map(Number);
    if (float >= start && float < end) {
      return index;
    }
  }

  return -1; // No range containing the float was found
}
