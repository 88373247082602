import { GetTransactionsByAccount200Response } from "@tradeblotter/tradeblotter-api";

import { assumeUTC } from "../utils/dates";

type SupportedAction = "BUY" | "SELL" | "SELL SHORT" | "UNKNOWN";

const actionFromBuySell = (buySell: string): SupportedAction => {
  switch (buySell.toUpperCase()) {
    case "BUY":
    case "BOT":
    case "BOUGHT":
      return "BUY";
    case "SELL":
    case "SLD":
    case "SOLD":
      return "SELL";
    case "SELL SHORT":
      return "SELL SHORT";
    default:
      console.warn(`Unknown action value: ${buySell}`);
      return "UNKNOWN";
  }
};

export default function transformAllTransactionsData(response: GetTransactionsByAccount200Response["results"]) {
  // TODO: This should take start and end dates into account
  const returnData = response.map((result) => ({
    id: result.id,
    tradeDatetime: assumeUTC(result.tradeDatetime),
    symbol: result.symbol,
    brokerageAlias: result.brokerageAlias,
    accountAlias: result.accountAlias,
    accountId: result.accountId,
    quantity: result.quantity,
    price: result.price,
    commission: result.commission,
    action: actionFromBuySell(result.buySell),
    thesis: result.journalEntry,
  }));

  return returnData;
}
