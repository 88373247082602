const SkeletonVerticalChart = () => {
  return (
    <div role="status" className="h-[415px] w-full animate-pulse rounded p-4 shadow dark:border-gray-700 md:p-6">
      <div className="mb-2.5 h-2.5 w-32 rounded-full bg-gray-600 dark:bg-gray-700"></div>
      <div className="mb-10 h-2 w-48 rounded-full bg-gray-600 dark:bg-gray-700"></div>
      <div className="mt-24 flex items-baseline">
        <div className="h-36 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-36 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-36 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-36 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-36 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
        <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-600 dark:bg-gray-700"></div>
      </div>
    </div>
  );
};

export default SkeletonVerticalChart;
