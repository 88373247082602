export const transformDrawdownToMonthDataSeries = (
  data: Array<{
    d: string;
    v: string;
  }>,
) => {
  const values = data.map((item) => {
    const percentage = parseFloat(item.v);
    const value = isNaN(percentage) ? 0 : percentage;
    const date = new Date(item.d).getTime();
    return [date, value];
  });

  return {
    data: { x: [data[0].d], y: [{ name: "Drawdown", data: values }] },
  };
};
