const ChartContainer = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return (
    <div
      style={{
        flex: "1 1 300px", // This makes the card grow and shrink, with a base width of 300px
        minWidth: "250px", // Ensures the card doesn't get too narrow
        marginBottom: "20px", // Adds space below cards when they wrap
      }}
    >
      {children}
    </div>
  );
};

export default ChartContainer;
