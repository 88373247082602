import { Section } from "@blueprintjs/core";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";

// import DateSelector from "../components/DateSelector";
import DrawdownChartComponent from "../components/widgets/Drawdown/DrawDown";
import { usePortfolioStatsAll } from "../hooks/usePortfolioStats";
import { useGlobalStore } from "../store/global";
import { percentageFormat } from "../utils/percentageFormat";

const DrawdownTable = () => {
  const { data } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);

  const daysFormatter = (value: number | string) => {
    if (value === null) {
      return "ongoing";
    } else {
      return value + " days";
    }
  };

  const recoveryFormatter = (value: number | string) => {
    if (value === null) {
      return "ongoing";
    } else {
      return value;
    }
  };

  const [colDefs, setColDefs] = useState([
    { field: "netDrawdown", valueFormatter: (p: { value: string }) => percentageFormat(p.value) },
    { field: "peakDate" },
    { field: "valleyDate" },
    {
      field: "recoveryDate",
      valueFormatter: (p: { value: string }) => recoveryFormatter(p.value !== "nan" ? p.value : 0),
    },
    {
      field: "duration",
      valueFormatter: (p: { value: string }) => (p.value ? daysFormatter(p.value) : "N/A"),
    },
  ]);

  // const pagination = true,
  //     paginationPageSize = 10,
  //     paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <Section title={"Top drawdown events"} style={{ padding: "2px", marginBottom: "20px" }}>
      <div
        className="ag-theme-balham-auto-dark" // applying the Data Grid theme
        style={{ height: 225 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          // pagination={pagination}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={data?.results.drawdownPeriods?.data[selectedPortfolio.id].drawdowns || []}
          // @ts-expect-error
          columnDefs={colDefs}
        />
      </div>
    </Section>
  );
};

const DrawdownChart = () => {
  return (
    <Section
      title="Portfolio drawdown"
      subtitle="A benchmark is a standard or measure that can be used to analyze the allocation, risk, and return of a given portfolio. Individual funds and investment portfolios will generally have established benchmarks for standard analysis. A variety of benchmarks can also be used to understand how a portfolio is performing against various market segments."
      // rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      <DrawdownChartComponent id="drawdown-page-chart" />
    </Section>
  );
};

const Drawdown = () => {
  return (
    <div>
      <DrawdownTable />
      <DrawdownChart />
    </div>
  );
};

export default Drawdown;
