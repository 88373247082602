import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

interface AppContextProps {
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  return <AppContext.Provider value={{ selectedItems, setSelectedItems }}>{children}</AppContext.Provider>;
};

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
