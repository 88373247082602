import * as Sentry from "@sentry/react";
// import { useEffect } from "react";
// import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { config } from "../config";

Sentry.init({
  dsn: "https://286fdadfba4be75daf0f0d830e3fdfc7@o1400634.ingest.sentry.io/4506192741793792",
  environment: process.env.SENTRY_ENVIRONMENT,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    // Sentry.reactRouterV6BrowserTracingIntegration({
      // useEffect: useEffect,
      // useLocation: useLocation,
      // useNavigationType: useNavigationType,
      // createRoutesFromChildren: createRoutesFromChildren,
      // matchRoutes: matchRoutes,
    // }),
    Sentry.replayIntegration({
      mutationLimit: 50000,
    }),
    Sentry.captureConsoleIntegration({
      levels: ["error", "assert"],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", config.API_BASE_URL],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});