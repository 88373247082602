import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import transformAllTransactionsData from "../../../data-transformers/allTransactionsData";
import { useTransactions } from "../../../hooks/useTransactions";
import { useGlobalStore } from "../../../store/global";

export const useTransformTransactions = (props: {
  id?: string;
  brokerageId?: string;
  accountId?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const { id, brokerageId, accountId, startDate: _startDate, endDate: _endDate } = props;
  const rawDataQuery = useTransactions({
    id,
    brokerageId,
    accountId,
  });
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount).id;

  const queryFn = useCallback(async () => {
    if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
      throw new Error("Raw data is not available");
    }

    return transformAllTransactionsData(rawDataQuery.data.results);
  }, [rawDataQuery]);
  return useQuery({
    queryKey: [
      "transformed",
      {
        type: "SharpeRatioData",
        selectedPortfolio,
        dataUpdatedAt: rawDataQuery.dataUpdatedAt,
      },
    ],
    queryFn,
    enabled: rawDataQuery.isSuccess,
    staleTime: Infinity,
  });
};
