import { Section } from "@blueprintjs/core";

// import DateSelector from "../../components/DateSelector";
import LineChart from "../../components/Charts/LineChart";
import SkeletonVerticalChart from "../../components/Charts/placeholders/SkeletonVerticalChart";
import { useTransformSharpeRatio } from "../../components/Dashboard/hooks/useTransformSharpeRatio";
import { usePortfolioStatsAll } from "../../hooks/usePortfolioStats";
import { percentageFormatter } from "../../utils/formatters";
import { useTransformRollingSortino } from "./transformation-hooks/useTransformRollingSortino";

const RollingSharpeRatio = () => {
  const { isLoading } = usePortfolioStatsAll();
  const { data: transformedSharpeRatioData, isError } = useTransformSharpeRatio();

  if (isError) {
    return <div>Error</div>;
  }
  return (
    <Section
      title={"Rolling Sharpe Ratio"}
      subtitle="The Sharpe ratio"
      // rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      {isLoading ? (
        <SkeletonVerticalChart />
      ) : (
        <LineChart
          data={transformedSharpeRatioData?.data.y || []}
          categories={transformedSharpeRatioData?.data.x || []}
          height={400}
          options={{
            chart: {
              id: "rolling-sharpe-ratio-chart-dashboard",
              zoom: {
                enabled: false,
              },
            },
            xaxis: {
              type: "datetime",
            },
            yaxis: {
              decimalsInFloat: 2,
              labels: {
                formatter: (value: number) => {
                  return percentageFormatter(value / 100, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  });
                },
              },
            },
          }}
        />
      )}
    </Section>
  );
};

const RollingSortinoRatio = () => {
  const { isLoading } = usePortfolioStatsAll();
  const { data } = useTransformRollingSortino();

  return (
    <Section
      title={"Rolling Sortino Ratio"}
      subtitle="The Sortino ratio"
      // rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      {isLoading ? (
        <SkeletonVerticalChart />
      ) : (
        <LineChart
          data={data?.data.y || []}
          categories={data?.data.x || []}
          height={400}
          options={{
            chart: {
              id: "rolling-sortino-ratio-chart-dashboard",
            },
            xaxis: {
              type: "datetime",
            },
            yaxis: {
              decimalsInFloat: 2,
              labels: {
                formatter: (value: number) => {
                  return percentageFormatter(value / 100, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  });
                },
              },
            },
          }}
        />
      )}
    </Section>
  );
};

const RollingPerformance = () => {
  return (
    <div>
      <RollingSharpeRatio />
      <RollingSortinoRatio />
    </div>
  );
};

export default RollingPerformance;
