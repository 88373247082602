// src/pages/Dashboard.js
import { Button, NonIdealState } from "@blueprintjs/core";
import React, { useEffect } from "react";

import { useDialog } from "../providers/DialogContext";
import { useMultiSelect } from "../providers/MultiSelectContext";

const Drift = () => {
  const { disableMultiSelect, enableMultiSelect } = useMultiSelect();

  const { openDialog } = useDialog();
  const handleMenuItemClick = (option) => {
    openDialog(option);
  };

  useEffect(() => {
    disableMultiSelect();
  }, [disableMultiSelect, enableMultiSelect]);

  return (
    <div>
      <NonIdealState
        icon="bullseye"
        title="Keep on top of your allocation targets"
        description="Set allocation targets and get notified when your target allocation drifts."
        action={
          <Button
            outlined
            text="Set allocation targets"
            icon="plus"
            intent="primary"
            onClick={() => handleMenuItemClick("new-allocation-dialog")}
          ></Button>
        }
      />
    </div>
  );
};

export default Drift;
