import { Colors, Section } from "@blueprintjs/core";

import { Card, CardsContainer } from "../components/Card";
import BarChart from "../components/Charts/BarChart";
// import DateSelector from "../components/DateSelector";
import HeatmapChart from "../components/Charts/HeatmapChart";
import SkeletonVerticalChart from "../components/Charts/placeholders/SkeletonVerticalChart";
import { useTransformValueAtRisk } from "../components/Dashboard/hooks/useTransformValueAtRisk";
import TooltipWithIcon from "../components/TooltipWithIcon";
import { enabledChartAnimation } from "../config";
// import { useAggregateReturns } from "../hooks/useAggregateReturns";
import { usePortfolioStatsAll } from "../hooks/usePortfolioStats";
import { useGlobalStore } from "../store/global";
import { percentageFormatter, scalarFormatter } from "../utils/formatters";

const ReturnsTable = () => {
  const { data } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);
  const selectedAccount = selectedPortfolio.id || "portfolio";
  const { totalPortfolioValues, rollingAnnualVolatility, rollingMaxDrawdown, alpha, beta } = data?.results || {};
  return (
    <Section
      title="Total (2 portfolios)"
      // rightElement={<DateSelector />}
      style={{ marginBottom: "20px" }}
    >
      <CardsContainer>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Annual Return" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(totalPortfolioValues?.data.cagr[selectedAccount]) || 0),
              }}
            >
              {percentageFormatter(Number(totalPortfolioValues?.data.cagr[selectedAccount]) || 0, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              })}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Cumulative Return" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(totalPortfolioValues?.data.totalReturnPercentage[selectedAccount]) || 0),
              }}
            >
              {percentageFormatter(Number(totalPortfolioValues?.data.totalReturnPercentage[selectedAccount]) || 0, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              })}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Annualized volatility" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(
                  Number(
                    rollingAnnualVolatility?.data[selectedAccount][
                      rollingAnnualVolatility?.data[selectedAccount].length - 1
                    ].v,
                  ) || 0,
                ),
              }}
            >
              {percentageFormatter(
                Number(
                  rollingAnnualVolatility?.data[selectedAccount][
                    rollingAnnualVolatility?.data[selectedAccount].length - 1
                  ].v,
                ) || 0,
                {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
                },
              )}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Sharpe ratio" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(totalPortfolioValues?.data.sharpeRatio[selectedAccount]) || 0),
              }}
            >
              {scalarFormatter(Number(totalPortfolioValues?.data.sharpeRatio[selectedAccount]) || 0, 2, 2)}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Omega ratio" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(totalPortfolioValues?.data.omegaRatio[selectedAccount]) || 0),
              }}
            >
              {scalarFormatter(Number(totalPortfolioValues?.data.omegaRatio[selectedAccount]) || 0, 2, 2)}
            </div>
          </div>
        </Card>
      </CardsContainer>
      <CardsContainer>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Calmar ratio" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(totalPortfolioValues?.data.calmarRatio[selectedAccount]) || 0),
              }}
            >
              {scalarFormatter(Number(totalPortfolioValues?.data.calmarRatio[selectedAccount]) || 0, 2, 2)}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Kurtosis" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(totalPortfolioValues?.data.kurtosis[selectedAccount]) || 0),
              }}
            >
              {scalarFormatter(Number(totalPortfolioValues?.data.kurtosis[selectedAccount]) || 0, 2, 2)}
            </div>
          </div>
        </Card>
        {/* <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
                    <h1>1.3</h1>
                    <p>Kurtosis</p>
                </Card> */}
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Alpha" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(alpha?.data[selectedAccount][alpha.data[selectedAccount].length - 1].v)),
              }}
            >
              {scalarFormatter(
                isNaN(Number(alpha?.data[selectedAccount][alpha.data[selectedAccount].length - 1].v))
                  ? 0
                  : Number(alpha?.data[selectedAccount][alpha.data[selectedAccount].length - 1].v),
                1,
                1,
              )}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Beta" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(Number(beta?.data[selectedAccount][beta.data[selectedAccount].length - 1].v)),
              }}
            >
              {scalarFormatter(
                isNaN(Number(beta?.data[selectedAccount][beta.data[selectedAccount].length - 1].v))
                  ? 0
                  : Number(beta?.data[selectedAccount][beta.data[selectedAccount].length - 1].v),
                1,
                1,
              )}
            </div>
          </div>
        </Card>
        <Card style={{ flex: "1 1", marginBottom: "20px", padding: 16 }}>
          <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
            <h3 className="text-sm font-semibold tracking-tight text-white">
              <TooltipWithIcon title="Max Drawdown" tip="..." />
            </h3>
          </div>
          <div className="p-6 pt-0">
            <div
              className="text-2xl font-bold"
              style={{
                color: returnColor(
                  Number(
                    rollingMaxDrawdown?.data[selectedAccount][rollingMaxDrawdown?.data[selectedAccount].length - 1].v,
                  ),
                ),
              }}
            >
              {percentageFormatter(
                isNaN(
                  Number(
                    rollingMaxDrawdown?.data[selectedAccount][rollingMaxDrawdown?.data[selectedAccount].length - 1].v,
                  ),
                )
                  ? 0
                  : Number(
                      rollingMaxDrawdown?.data[selectedAccount][rollingMaxDrawdown?.data[selectedAccount].length - 1].v,
                    ),
                {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
                },
              ) || "N/A"}
            </div>
          </div>
        </Card>
      </CardsContainer>
    </Section>
  );
};

const returnColor = (value: number) => {
  if (value === 0 || isNaN(value)) {
    return Colors.GRAY4;
  }
  if (value > 0) {
    return Colors.GREEN4;
  }
  return Colors.RED4;
};

const ReturnsHistogram = () => {
  const { isLoading } = usePortfolioStatsAll();
  return (
    <Section
      title="Returns distribution"
      // rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      {isLoading ? <SkeletonVerticalChart /> : <ReturnsChart />}
    </Section>
  );
};

const ReturnsChart = () => {
  const { isLoading } = usePortfolioStatsAll();
  const {
    transformedValueAtRiskData: { data, isError },
    binWithVarValue,
  } = useTransformValueAtRisk();

  if (isError) {
    return <div>Error</div>;
  }
  return (
    <BarChart
      key={"returns-histogram-returns-page-chart"}
      data={data?.results?.data || []}
      categories={data?.results?.categories || []}
      options={{
        chart: {
          id: "returns-histogram-returns-page-chart",
          group: "returns-histogram-returns-page-chart",
          animations: {
            enabled: enabledChartAnimation,
          },
          zoom: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return scalarFormatter(value, 0, 0);
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: "#FFFFFF",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        annotations: {
          xaxis: [
            {
              x: binWithVarValue, // x-axis value where the line should be drawn
              borderColor: "#775DD0", // color of the line
            },
          ],
        },
        noData: {
          text: isLoading ? "Loading..." : "No data available",
        },
      }}
    />
  );
};

const ReturnsHeatmap = () => {
  const { isLoading, data } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);
  // const { data: aggregateReturns, isLoading: isLoadingAggregateReturns } = useAggregateReturns();
  // console.log(aggregateReturns, isLoadingAggregateReturns);

  const transformedData = transformDataForHeatmap(data?.results?.aggregateReturns?.data[selectedPortfolio.id] || []);

  return (
    <Section title="Returns heatmap" style={{ padding: "2px", marginBottom: "20px" }}>
      {isLoading ? (
        <SkeletonVerticalChart />
      ) : (
        <HeatmapChart
          data={transformedData}
          options={{
            chart: {
              zoom: {
                enabled: false,
              },
            },
            yaxis: {
              reversed: true,
            },
            dataLabels: {
              formatter: function (val) {
                return percentageFormatter(Number(val), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                });
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return percentageFormatter(Number(val), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                },
              },
            },
            noData: {
              text: "No data available",
            },
          }}
        />
      )}
    </Section>
  );
};

const ReturnsBarChart = () => {
  const { isLoading, data } = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);

  const transformedData = transformDataForReturnsPerYear(
    data?.results?.aggregateReturns?.data[selectedPortfolio.id] || [],
  );

  return (
    <Section
      title="Returns per year"
      // rightElement={<DateSelector />}
      style={{ padding: "2px", marginBottom: "20px" }}
    >
      {isLoading ? (
        <SkeletonVerticalChart />
      ) : (
        <BarChart
          key={"returns-per-year-chart-dashboard"}
          data={transformedData.y || []}
          categories={transformedData.x || []}
          options={{
            chart: {
              group: "return-per-year-chart-dashboard",
              id: "returns-per-year-chart-dashboard",
              animations: {
                enabled: enabledChartAnimation,
              },
              zoom: {
                enabled: false,
              },
            },
            xaxis: {
              labels: {
                formatter: (value) => {
                  return percentageFormatter(Number(value), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                },
                style: {
                  colors: "#FFFFFF",
                },
              },
              max: Math.abs(Math.ceil(Math.abs(transformedData.max) * 100) / 100),
              min: Math.floor(transformedData.min * 100) / 100,
              tickAmount: 10,
            },
            dataLabels: {
              formatter: function (val, opt) {
                return percentageFormatter(Number(val), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                });
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return percentageFormatter(Number(val), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                },
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "all", // 'all', 'last'
                horizontal: true,
                barHeight: "80%",
              },
            },
            noData: {
              text: "No data available",
            },
          }}
        />
      )}
    </Section>
  );
};

const Returns = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px", // This adds space between the cards
          flexWrap: "wrap", // This allows cards to wrap to next line on smaller screens
        }}
      >
        <ReturnsTable />
        <ReturnsHistogram />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px", // This adds space between the cards
          flexWrap: "wrap", // This allows cards to wrap to next line on smaller screens
        }}
      >
        <div
          style={{
            flex: "1 1 300px", // This makes the card grow and shrink, with a base width of 300px
            minWidth: "250px", // Ensures the card doesn't get too narrow
            marginBottom: "20px", // Adds space below cards when they wrap
          }}
        >
          <ReturnsHeatmap />
        </div>
        <div
          style={{
            flex: "1 1 300px",
            minWidth: "250px",
            marginBottom: "20px",
          }}
        >
          <ReturnsBarChart />
        </div>
      </div>
    </div>
  );
};

const transformDataForHeatmap = (data: { d: string; v: string }[]) => {
  return data.map((item) => ({
    name: item.d,
    data: [Number(item.v)],
  }));
};

const transformDataForReturnsPerYear = (data: { d: string; v: string }[]) => {
  const categories = Array.from(new Set(data.map((item) => parseInt(item.d).toString())));

  const maxVal = Math.max(...data.map((item) => Number(item.v)));
  const minVal = Math.min(...data.map((item) => Number(item.v)));
  const formattedData = data.map((item) => ({
    x: new Date(item.d).getFullYear().toString(),
    y: Number(item.v),
  }));

  return {
    x: categories,
    y: [
      {
        data: formattedData,
      },
    ],
    max: maxVal,
    min: minVal,
  };
};

// @TODO: I expect data in api change to handle month/year so I will save this for now
// const transformDataForHeatmapByYear = (data: { d: string; v: string }[]) => {
//     const groupedByYear: {
//         [key: string]: { [key: string]: { total: number; count: number } };
//     } = {};

//     data.forEach((item) => {
//         const year = new Date(item.d).getFullYear().toString(); // Extract the year
//         const month = new Date(item.d).getMonth() + 1; // Extract the month (0-indexed, so +1)
//         const yearMonth = `${year}-${String(month).padStart(2, "0")}`; // Format as "YYYY-MM"
//         const value = parseFloat(item.v); // Convert value to float

//         if (!groupedByYear[year]) {
//             groupedByYear[year] = {};
//         }

//         if (!groupedByYear[year][yearMonth]) {
//             groupedByYear[year][yearMonth] = { total: 0, count: 0 }; // Initialize total and count
//         }

//         // Accumulate total and count for each year-month
//         groupedByYear[year][yearMonth].total += value;
//         groupedByYear[year][yearMonth].count += 1;
//     });

//     // Create the final array for heatmap data
//     const result = Object.keys(groupedByYear).map((year) => ({
//         name: year, // Year as the name
//         data: Object.keys(groupedByYear[year]).map((yearMonth) => ({
//             x: yearMonth, // Year-Month as x-axis label
//             y:
//                 groupedByYear[year][yearMonth].total /
//                 groupedByYear[year][yearMonth].count, // Average value for the month
//         })),
//     }));

//     return result;
// };

export default Returns;
