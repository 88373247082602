const SkeletonTable = () => {
  return (
    <div role="status" className="w-full animate-pulse space-y-4 divide-y divide-gray-500 rounded p-4 shadow md:p-6">
      {/* Table Header */}
      <div className="flex items-center justify-between space-x-12">
        <div className="h-2 w-1/6 rounded bg-gray-600"></div>
        <div className="h-2 w-1/12 rounded bg-gray-600"></div>
        <div className="h-2 w-1/6 rounded bg-gray-600"></div>
        <div className="h-2 w-1/12 rounded bg-gray-600"></div>
        <div className="h-2 w-1/6 rounded bg-gray-600"></div>
        <div className="h-2 w-1/12 rounded bg-gray-600"></div>
        <div className="h-2 w-1/6 rounded bg-gray-600"></div>
        <div className="h-2 w-1/12 rounded bg-gray-600"></div>
      </div>

      {/* Table Rows */}
      {Array.from({ length: 5 }).map((_, index) => (
        <div className="flex items-center justify-between space-x-12 pt-4" key={index}>
          <div className="h-2 w-1/6 rounded bg-gray-600"></div>
          <div className="h-2 w-1/12 rounded bg-gray-600"></div>
          <div className="h-2 w-1/6 rounded bg-gray-600"></div>
          <div className="h-2 w-1/12 rounded bg-gray-600"></div>
          <div className="h-2 w-1/6 rounded bg-gray-600"></div>
          <div className="h-2 w-1/12 rounded bg-gray-600"></div>
          <div className="h-2 w-1/6 rounded bg-gray-600"></div>
          <div className="h-2 w-1/12 rounded bg-gray-600"></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonTable;
