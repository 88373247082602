import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { transformPortfolioValueToMonthDataSeries } from "../../../data-transformers/portfolio-value-to-month-series-data";
import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../store/global";

export const useTransformPortfolioValueToMonth = () => {
  const rawDataQuery = usePortfolioStatsAll();
  const selectedPortfolio = useGlobalStore((state) => state.selectedAccount).id;
  const queryFn = useCallback(async () => {
    if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
      throw new Error("Raw data is not available");
    }

    return transformPortfolioValueToMonthDataSeries(
      rawDataQuery.data.results.rollingPortfolioValue?.data[selectedPortfolio] || [],
    );
  }, [rawDataQuery, selectedPortfolio]);

  return useQuery({
    queryKey: [
      "transformed",
      {
        type: "PortfolioValueData",
        selectedPortfolio,
        dataUpdatedAt: rawDataQuery.dataUpdatedAt,
      },
    ],
    queryFn,
    enabled: rawDataQuery.isSuccess,
    staleTime: Infinity,
  });
};
