import z from "zod";

// Define the shape of your configuration
export type Config = z.infer<typeof configSchema>;

// Define the schema for your environment variables
const configSchema = z.object({
  API_BASE_URL: z.string().min(1, "API_KEY is required"),
});

// Parse and validate environment variables using the schema
const config = configSchema.parse({
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
});

export { config };

export const enabledChartAnimation: boolean = false;
