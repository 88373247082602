import { useEffect } from "react";

export function useOutsetaEvents() {
  useEffect(() => {
    const events = [
      // "accessToken.set",
      // "auth.initialized",
      // "auth.close",
      // "signup.registrationData",
      // "redirect",
      // "signup.preRegister",
      // "signup",
      // "logout",
      "nocode.accessDenied",
      "nocode.expired",
      // "nocode.initialized",
      // "account.update",
      // "paymentInformation.update",
      // "profile.close",
      // "profile.initialized",
      // "profile.update",
      // "subscription.cancel",
      // "subscription.reopen",
      // "subscription.update",
    ];

    events.forEach((event) => {
      if (!window.AppOutseta) {
        // console.warn(`Outseta is not available. Event ${event} will not be handled.`);
        return;
      }
      window.AppOutseta.on(event, async (...params) => {
        // console.log(event, params);
        if (event === "nocode.expired" || event === "nocode.accessDenied") {
          // window.location.reload();
          window.location.href = "/login";
        }
      });
    });
  }, []);
}
