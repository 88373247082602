import _ from "lodash";
import Chart from "react-apexcharts";

interface ISparkLinesChartProps {
  data: ApexCharts.ApexOptions["series"];
  options?: ApexCharts.ApexOptions;
  categories?: string[];
  height?: number;
}

const SparkLinesChart = (props: ISparkLinesChartProps) => {
  const { data, categories = [], options } = props;

  const baseOptions: ApexCharts.ApexOptions = {
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    fill: {
      opacity: 0.3,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: "#f4f5f7",
        },
      },
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    title: {
      text: undefined,
    },
    // This one produces an error, not sure why it was being applied
    // annotations: {
    //     yaxis: [{ y: 0 }]
    // }
    noData: {
      text: "No data available",
      style: {
        fontSize: "12px",
        color: "#ffffff",
      },
    },
  };

  if (categories?.length && baseOptions.xaxis?.categories) {
    baseOptions.xaxis.categories = categories;
  }

  return <Chart options={_.merge(baseOptions, options)} series={data} type="area" />;
};

export default SparkLinesChart;
