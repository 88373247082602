import { Section } from "@blueprintjs/core";

import TooltipWithIcon from "../../TooltipWithIcon";
// import DateSelector from "../../DateSelector";
import HistoricAllocation from "./HistoricAllocation";

const HistoricAllocationContainer = (props: { id: string }) => {
  return (
    <Section
      title={<TooltipWithIcon title="Position allocations" tip="..." />}
      // rightElement={<DateSelector />}
      style={{ padding: "2px" }}
    >
      <HistoricAllocation id={props.id} />
    </Section>
  );
};

export default HistoricAllocationContainer;
