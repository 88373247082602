import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";

import { AppProvider } from "./context/AppContext";
import { useOutsetaEvents } from "./hooks/useOutsetaEvents";
import { MainWithAuth } from "./layouts/MainWithAuth";
import Allocation from "./pages/Allocation";
import Correlation from "./pages/Correlation";
import Dashboard from "./pages/Dashboard";
import Drawdown from "./pages/Drawdown";
import Drift from "./pages/Drift";
import Equity from "./pages/Equity";
import Factor from "./pages/Factor";
import Holdings from "./pages/Holdings";
import Leverage from "./pages/Leverage";
import Portfolios from "./pages/Portfolios";
import Returns from "./pages/Returns";
import RollingPerformance from "./pages/RollingPerformance";
import RollingRisk from "./pages/RollingRisk";
import Symbol from "./pages/Symbol";
import ThankYouPage from "./pages/ThankYouPage";
import Transactions from "./pages/Transactions";
import { DialogProvider } from "./providers/DialogContext";
import { MultiSelectProvider } from "./providers/MultiSelectContext";
import "./sentry";

const routes = [
  {
    path: "/",
    element: (
      <MultiSelectProvider>
        <MainWithAuth />
      </MultiSelectProvider>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: "onboarding", element: <Navigate to="/dashboard" replace /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "transactions", element: <Transactions /> },
      { path: "holdings", element: <Holdings /> },
      { path: "holdings/:symbolId", element: <Symbol /> },
      { path: "equity", element: <Equity /> },
      { path: "rollingPerformance", element: <RollingPerformance /> },
      { path: "drawdown", element: <Drawdown /> },
      { path: "rollingRisk", element: <RollingRisk /> },
      { path: "leverage", element: <Leverage /> },
      { path: "drift", element: <Drift /> },
      { path: "returns", element: <Returns /> },
      { path: "allocation", element: <Allocation /> },
      { path: "factorAttribution", element: <Factor /> },
      { path: "correlation", element: <Correlation /> },
      { path: "portfolios", element: <Portfolios /> },
    ],
  },
  {
    path: "/thank-you",
    element: <ThankYouPage />,
  },
];

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(routes);

function App() {
  useOutsetaEvents();
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <AppProvider>
        <DialogProvider>
          <div
            className="bp5-dark"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              backgroundColor: "#2f343c",
              font: "12px 'Helvetica Neue', helvetica, arial, verdana, sans-serif",
            }}
          >
            <RouterProvider router={router} />
          </div>
        </DialogProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
