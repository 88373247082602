export function findPercentile(
  values: Array<{
    d: string;
    v: string;
  }>,
  percentile: number,
) {
  const returns = values.map((item) => parseFloat(item.v));

  // Sort the values in ascending order
  const sortedValues = returns.slice().sort((a, b) => a - b);

  // Calculate the index of the percentile value
  const index = (percentile / 100) * (sortedValues.length - 1);
  const lowerIndex = Math.floor(index);
  const upperIndex = Math.ceil(index);

  // If the index is an integer, return the value at that index
  if (lowerIndex === upperIndex) {
    return sortedValues[lowerIndex];
  }

  // Otherwise, interpolate between the two nearest values
  const lowerValue = sortedValues[lowerIndex];
  const upperValue = sortedValues[upperIndex];
  const fraction = index - lowerIndex;

  return lowerValue + (upperValue - lowerValue) * fraction;
}
